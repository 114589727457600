import React, { useEffect, useState } from "react";
import {
  Divider,
  Tooltip,
  List,
  Avatar,
  Spin,
  Tabs,
  Input,
  Button,
} from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import logo from "../noImg.png";
import axios from "axios";
import { CHAINS_CONFIG } from "../chains";
import { ethers } from "ethers";

function WalletView({
  wallet,
  setWallet,
  seedPhrase,
  setSeedPhrase,
  selectedChain,
}) {
  const navigate = useNavigate();
  const [tokens, setTokens] = useState(null);
  const [nfts, setNfts] = useState(null);
  const [balance, setBalance] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [amountToSend, setAmountToSend] = useState(null);
  const [sendToAddress, setSendToAddress] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [hash, setHash] = useState(null);
  const [newTokenAddress, setNewTokenAddress] = useState("");  
  const [newTokenSymbol, setNewTokenSymbol] = useState("");    
  const [newTokenName, setNewTokenName] = useState("");        
  const [showAddTokenForm, setShowAddTokenForm] = useState(false);

  const items = [
    {
      key: "3",
      label: `Tokens`,
      children: (
        <>
          {tokens && tokens.length > 0 ? (
            <List
              bordered
              itemLayout="horizontal"
              dataSource={tokens}
              renderItem={(item, index) => (
                <List.Item style={{ textAlign: "left" }}>
                  <List.Item.Meta
                    avatar={<Avatar src={item.logo || logo} />}
                    title={item.symbol}
                    description={item.name}
                  />
                  <div>
                    {(Number(item.balance) / 10 ** Number(item.decimals)).toFixed(2)} Tokens
                  </div>
                </List.Item>
              )}
            />
          ) : (
            <>
              <span>You seem to not have any tokens yet</span>
              <p className="frontPageBottom">
                Find Alt Coin Gems:{" "}
                <a href="https://Bait-coin.com/" target="_blank" rel="noreferrer">
                  Bait-coin.com
                </a>
              </p>
            </>
          )}

          <Button
            style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
            type="primary"
            onClick={() => setShowAddTokenForm(!showAddTokenForm)} 
          >
            Add Token
          </Button>

          {showAddTokenForm && (
            <>
              <Input
                value={newTokenAddress}
                onChange={(e) => setNewTokenAddress(e.target.value)}
                placeholder="Token address"
              />
              <Input
                value={newTokenSymbol}
                onChange={(e) => setNewTokenSymbol(e.target.value)}
                placeholder="Token symbol"
              />
              <Input
                value={newTokenName}
                onChange={(e) => setNewTokenName(e.target.value)}
                placeholder="Token name"
              />
              <Button
                style={{
                  width: "100%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                type="primary"
                onClick={addToken}
              >
                Add Token
              </Button>
            </>
          )}
        </>
      ),
    },
    {
      key: "2",
      label: `NFTs`,
      children: nfts ? (
        nfts.map((e, i) => e && <img key={i} className="nftImage" alt="nftImage" src={e} />)
      ) : (
        <>
          <span>You seem to not have any NFTs yet</span>
          <p className="frontPageBottom">
            Find Alt Coin Gems:{" "}
            <a href="https://Bait-coin.com/" target="_blank" rel="noreferrer">
              Bait-coin.com
            </a>
          </p>
        </>
      ),
    },
    {
      key: "1",
      label: `Transfer`,
      children: (
        <>
          <h3>Native Balance </h3>
          <h1>{balance.toFixed(10)} {CHAINS_CONFIG[selectedChain]?.ticker || 'BAIT'}</h1>
          <div className="sendRow">
            <p style={{ width: "90px", textAlign: "left" }}>To:</p>
            <Input
              value={sendToAddress}
              onChange={(e) => setSendToAddress(e.target.value)}
              placeholder="0x..."
            />
          </div>
          <div className="sendRow">
            <p style={{ width: "90px", textAlign: "left" }}>Amount:</p>
            <Input
              value={amountToSend}
              onChange={(e) => setAmountToSend(e.target.value)}
              placeholder="Native tokens you wish to send..."
            />
          </div>
          <Button
            style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
            type="primary"
            onClick={() => sendTransaction(sendToAddress, amountToSend)}
          >
            Send Tokens
          </Button>
          {processing && (
            <>
              <Spin />
              {hash && (
                <Tooltip title={hash}>
                  <p>Hover For Tx Hash</p>
                </Tooltip>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  async function addToken() {
    if (!newTokenAddress || !newTokenSymbol || !newTokenName) {
      console.error("Please fill in all fields");
      return;
    }

    try {
      const response = await axios.post(
        "http://localhost:3000/api/v1/tokens/createtoken",
        { name: newTokenName, address: newTokenAddress, symbol: newTokenSymbol }
      );

      const newToken = response.data.data.createToken;
      setTokens((prevTokens) => [...prevTokens, newToken]);
      setNewTokenAddress("");
      setNewTokenSymbol("");
      setNewTokenName("");
    } catch (error) {
      console.error("Error adding token:", error);
    }
  }

  async function sendTransaction(to, amount) {
    const chain = CHAINS_CONFIG[selectedChain];
    const provider = chain ? new ethers.JsonRpcProvider(chain.rpcUrl) : null;
    const privateKey = ethers.Wallet.fromPhrase(seedPhrase).privateKey;
    const wallet = new ethers.Wallet(privateKey, provider);

    const tx = { to: to, value: ethers.parseEther(amount.toString()) };

    setProcessing(true);
    try {
      const transaction = await wallet.sendTransaction(tx);
      setHash(transaction.hash);
      const receipt = await transaction.wait();
      setProcessing(false);
      setAmountToSend(null);
      setSendToAddress(null);

      if (receipt.status === 1) {
        getAccountTokens();
      } else {
        console.log("Transaction failed");
      }
    } catch (err) {
      setProcessing(false);
      setHash(null);
      console.error("Error sending transaction:", err);
    }
  }

  async function getAccountTokens() {
    setFetching(true);

    try {
      const res = await axios.get("http://localhost:3000/getTokens", {
        params: { userAddress: wallet, chain: selectedChain },
      });

      const response = res.data;
      setTokens(response.tokens);
      setNfts(response.nfts);
      setBalance(response.balance);
    } catch (error) {
      console.error("Error fetching tokens:", error);
    }

    setFetching(false);
  }

  function logout() {
    setSeedPhrase(null);
    setWallet(null);
    setNfts(null);
    setTokens(null);
    setBalance(0);
    navigate("/");
  }

  useEffect(() => {
    if (wallet && selectedChain) {
      getAccountTokens();
    }
  }, [wallet, selectedChain]);
  

  return (
    <div className="content">
      <div className="logoutButton" onClick={logout}>
        <LogoutOutlined />
      </div>
      <div className="walletName">Wallet</div>
      <Tooltip title={wallet}>
        <div>{wallet.slice(0, 4)}...{wallet.slice(38)}</div>
      </Tooltip>
      <Divider />
      {fetching ? <Spin /> : <Tabs defaultActiveKey="1" items={items} />}
    </div>
  );
}

export default WalletView;
